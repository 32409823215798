<template>
  <section>
    NotFound content
  </section>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
